'use client';
import axios from 'axios';
import { createContext, useState, useContext, useEffect } from 'react';
import { useAuthContext } from '.';
import { useAlertContext } from './alert';

// Create the cart context with default values
const FavoritesContext = createContext({
	loading: false,
	listings: [],
	ADD_TO_FAVORITES: () => {},
	REMOVE_FROM_FAVORITES: () => {},
});

// Create a provider component for the cart context
export const FavoritesProvider = ({ children }) => {
	const [favorites, setFavorites] = useState({
		loading: false,
		listings: [],
	});

	const { auth } = useAuthContext();
	const { SET_ALERT } = useAlertContext();

	const ADD_TO_FAVORITES = async (listingId) => {
		if (!auth.isAuthenticated) {
			SET_ALERT({
				msg: 'You have to be logged in first',
				type: 'danger',
			});
			return;
		}

		setFavorites((prevState) => ({
			...prevState,
			loading: true,
		}));

		for (let item of favorites?.listings) {
			if (item._id === listingId) {
				SET_ALERT({
					msg: 'Item is already present in your favorites',
					type: 'danger',
				});

				setFavorites((prevState) => ({
					...prevState,
					loading: false,
				}));
				return;
			}
		}
		let userFavorites = [];
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/cart/favorites/${listingId}`
			)
			.then((res) => {
				setFavorites((prevState) => ({
					listings: res.data,
					loading: true,
				}));

				SET_ALERT({
					msg: `Added to favorites`,
					type: 'success',
				});

				userFavorites = res.data;
			})
			.catch((error) => {
				setFavorites((prevState) => ({
					...prevState,
					loading: false,
				}));
			});

		let segmentFavorites = [];
		for (let listing of userFavorites) {
			if (listing.status === 'active') {
				segmentFavorites.push({
					item_id: listing._id,
					item_name: listing.title,
					item_estRetailPrice:
						listing.estRetailPrice &&
						listing.estRetailPrice !== 'null' &&
						listing.estRetailPrice !== 'undefined'
							? listing.estRetailPrice
							: null,
					item_image: listing.images[0],
					item_brand: listing.brand
						? listing.brand
						: listing.velouAttributes?.manufacturer?.length > 0
						? listing.velouAttributes?.manufacturer[0]
								?.replace(/_/g, ' ')
								?.split(' ')
								?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								?.join(' ')
						: listing.velouAttributes?.brands?.length > 0
						? listing.velouAttributes?.brands[0]
								?.replace(/_/g, ' ')
								?.split(' ')
								?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								?.join(' ')
						: '',
					item_category: listing.newSchema.categories[0],
					item_category2: listing.newSchema.subCategories[0]
						? listing.newSchema.subCategories[0]
						: '',
					item_category3: listing.newSchema.types[0]
						? listing.newSchema.types[0]
						: '',
					item_price: listing.price,
				});
			}
		}

		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			process.env.OG_DB === 'true' &&
			global?.window &&
			window.analytics.identify(auth.user._id, {
				favorites:
					segmentFavorites.length > 0 ? JSON.stringify(segmentFavorites) : '',
				firstName: auth.user.firstName,
				lastName: auth.user.lastName ? auth.user.lastName : '',
				phone: auth.user.phone ? auth.user.phone : '',

				email: auth.user.email,
			});
	};

	const REMOVE_FROM_FAVORITES = async (listingId) => {
		setFavorites((prevState) => ({
			...prevState,
			loading: true,
		}));

		let userFavorites = [];

		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/cart/favorites/remove/${listingId}`
			)
			.then(async (res) => {
				SET_ALERT({
					msg: `Removed from favorites`,
					type: 'danger',
				});

				setFavorites((prevState) => ({
					listings: res.data,
					loading: false,
				}));
				userFavorites = res.data;
			})

			.catch((error) => {
				setFavorites((prevState) => ({
					...prevState,
					loading: false,
				}));
			});
		let segmentFavorites = [];
		for (let listing of userFavorites) {
			if (listing.status === 'active') {
				segmentFavorites.push({
					item_id: listing._id,
					item_name: listing.title,
					item_estRetailPrice:
						listing.estRetailPrice &&
						listing.estRetailPrice !== 'null' &&
						listing.estRetailPrice !== 'undefined'
							? listing.estRetailPrice
							: null,
					item_image: listing.images[0],
					item_brand: listing.brand
						? listing.brand
						: listing.velouAttributes?.manufacturer?.length > 0
						? listing.velouAttributes?.manufacturer[0]
								?.replace(/_/g, ' ')
								?.split(' ')
								?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								?.join(' ')
						: listing.velouAttributes?.brands?.length > 0
						? listing.velouAttributes?.brands[0]
								?.replace(/_/g, ' ')
								?.split(' ')
								?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								?.join(' ')
						: '',
					item_category: listing.newSchema.categories[0],
					item_category2: listing.newSchema.subCategories[0]
						? listing.newSchema.subCategories[0]
						: '',
					item_category3: listing.newSchema.types[0]
						? listing.newSchema.types[0]
						: '',
					item_price: listing.price,
				});
			}
		}

		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			process.env.OG_DB === 'true' &&
			global?.window &&
			window.analytics.identify(auth.user._id, {
				favorites:
					segmentFavorites.length > 0 ? JSON.stringify(segmentFavorites) : '',
				firstName: auth.user.firstName,
				lastName: auth.user.lastName ? auth.user.lastName : '',
				phone: auth.user.phone ? auth.user.phone : '',

				email: auth.user.email,
			});
	};

	const LOAD_FAVORITES = async (ids) => {
		setFavorites((prevState) => ({
			...prevState,
			loading: true,
		}));

		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/listing-with-ids`,
				{
					ids: ids,
					limit: 40,
				}
			)
			.then((res) => {
				setFavorites((prevState) => ({
					listings: res.data,
					loading: false,
				}));
			})
			.catch((error) => {
				setFavorites((prevState) => ({
					...prevState,
					loading: false,
				}));
			});
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			LOAD_FAVORITES(auth.user.favorites);
		}
	}, [auth]);

	return (
		<FavoritesContext.Provider
			value={{
				favorites,
				ADD_TO_FAVORITES,
				REMOVE_FROM_FAVORITES,
			}}
		>
			{children}
		</FavoritesContext.Provider>
	);
};

export const useFavoritesContext = () => {
	return useContext(FavoritesContext);
};
