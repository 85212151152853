'use client';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { usePathname } from 'next/navigation';
import useLocalStorage from '../Hooks/UseLocalStorage';
import classes from './FavoriteIcon.module.css';
import { useAuthContext } from '@/context';
import AuthUser from '@/utils/AuthUser';
import { useFavoritesContext } from '@/context/favorites';

const FavoriteIcon = (props) => {
	const [requestAuth, setRequestAuth] = useState(false);
	const { listing, noBorderClass } = props;

	const { favorites, ADD_TO_FAVORITES, REMOVE_FROM_FAVORITES } =
		useFavoritesContext();

	const { auth } = useAuthContext();

	const pathname = usePathname();

	const [favoriteItems, setFavoriteItems] = useLocalStorage('favorites', []);

	const addToFavoritesHandler = async (e) => {
		e.stopPropagation(); // Correct placement of stopPropagation
		e.preventDefault();

		if (!auth.isAuthenticated) {
			setRequestAuth(true);
			return;
		}

		ADD_TO_FAVORITES(listing._id);
		setFavoriteItems([
			...favoriteItems,
			{ id: listing._id, listingPrice: listing.price },
		]);

		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			process.env.OG_DB === 'true' &&
			global?.window &&
			window.analytics.track('add_to_favorites', {
				currency: 'USD',
				value: (listing.price * 0.2).toFixed(0),
				index: 'kashew_listings',
				queryID: localStorage.getItem('queryID')
					? localStorage.getItem('queryID')
					: '',
				items: [
					{
						item_id: listing._id,
						item_name: listing.title,
						currency: 'USD',
						item_brand: listing.brand ? listing.brand : '',
						item_category: listing.newSchema.categories[0],
						item_category2: listing.newSchema.subCategories[0]
							? listing.newSchema.subCategories[0]
							: '',
						item_category3: listing.newSchema.types[0]
							? listing.newSchema.types[0]
							: '',
						price: listing.price,
					},
				],
			});
	};

	const removeFromFavoritesHandler = (event) => {
		event.stopPropagation(); // Ensure this is called to stop the event from bubbling up
		event.preventDefault();

		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			process.env.OG_DB === 'true' &&
			global?.window &&
			window.analytics.track('remove_from_favorites', {
				currency: 'USD',
				value: listing.price * 0.2,
				items: [
					{
						item_id: listing._id,
						item_name: listing.title,
						currency: 'USD',
						item_brand: listing.brand ? listing.brand : '',
						item_category: listing.newSchema.categories[0],
						item_category2: listing.newSchema.subCategories[0]
							? listing.newSchema.subCategories[0]
							: '',
						item_category3: listing.newSchema.types[0]
							? listing.newSchema.types[0]
							: '',
						price: listing.price,
					},
				],
			});
		const updatedFavoriteItems = favoriteItems.filter(
			(item) => item.id !== listing._id
		);
		setFavoriteItems(updatedFavoriteItems);

		REMOVE_FROM_FAVORITES(listing._id);
	};

	const [favoritesUsers, setFavoritesUsers] = useState();

	const getFavLength = async () => {
		const isBot = /bot|crawl|slurp|spider|Prerender|facebookexternalhit/i.test(
			navigator.userAgent
		);

		if (!auth.isAuthenticated || isBot) return;
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/users/favorites/all/${listing._id}`
			)
			.then((res) => {
				setFavoritesUsers(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (pathname.includes('/product')) {
			getFavLength();
		} else {
			return;
		}
	}, [listing]);

	return (
		<>
			{requestAuth && (
				<AuthUser
					open={requestAuth}
					setRequestAuth={setRequestAuth}
					from={'favorites'}
				/>
			)}

			{favorites?.listings?.find((i) => i?._id === listing._id) ? (
				<>
					<div className={`${classes.favDiv} ${noBorderClass}`}>
						<AiFillHeart
							className={classes.favoritePartOf}
							size={20}
							onClick={removeFromFavoritesHandler}
						/>
						{favoritesUsers?.length > 0 && (
							<div className={classes.length}>{favoritesUsers.length}</div>
						)}
					</div>
				</>
			) : (
				<div className={`${classes.favDiv} ${noBorderClass}`}>
					<AiOutlineHeart
						className={classes.favoriteIcon}
						size={20}
						onClick={addToFavoritesHandler}
					/>
					{favoritesUsers?.length > 0 && (
						<div className={classes.length}>{favoritesUsers.length}</div>
					)}
				</div>
			)}
		</>
	);
};

export default FavoriteIcon;
